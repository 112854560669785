<template>
    <!-- :class="{ 'bg-slate-800': s.theme == 'dark' }" -->
    <!-- {{ s.theme }} -->
    <div class="grid grid-cols-1 md:grid-cols-5 gap-4 min-h-[calc(100vh-80px)]">
        <div class="sticky top-16 bg-white dark:bg-slate-700 rounded p-4 overflow-y-auto">
            <div class="flex text-base text-slate-400 dark:text-slate-300 flex-col items-center">
                <div class="w-48 h-48 my-5 mx-auto rounded-full bg-slate-200 dark:bg-slate-600 overflow-hidden relative">
                    <span class="w-full h-full group">
                        <input type="file" id="avatar" class="absolute invisible opacity-0" multiple @change="uploadAvatar({ e: $event })" />
                        <label
                            for="avatar"
                            class="w-1/2 h-1/2 flex items-center justify-center rounded-full absolute -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-20 group-hover:bg-black group-hover:opacity-50 group-hover:top-1/2"
                        >
                            <svg class="fill-white w-5 h-5"><use xlink:href="@/assets/i.svg#ico-edit"></use></svg>
                        </label>

                        <img
                            v-if="profile?.picture"
                            class="w-full h-full object-cover group-hover:scale-105 duration-150"
                            :src="'https://statapi.timekraken.ru/users/' + profile?.picture"
                        />
                        <img v-else class="w-full h-full object-cover" src="https://statapi.timekraken.ru/users/mini/u0.png" />
                    </span>
                </div>

                {{ profile?.name }} {{ profile?.secondname }}
            </div>
            <div class="flex">
                <!-- <a class="mx-auto mt-4 v-btn bg-sky-500 hover:bg-sky-400 transition-colors duration-150" @click="editProfile">
                        <svg class="fill-white w-16px h-16px mr-3">
                            <use xlink:href="@/assets/i.svg#ico-edit"></use>
                        </svg>
                        <span class="v-btn_text">РЕДАКТИРОВАТЬ</span>
                    </a> -->
                <a
                    class="mx-auto mt-4 v-btn py-2 px-4 cursor-pointer rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-150"
                    @click="logout()"
                >
                    <span class="text-white">ВЫХОД</span>
                </a>
            </div>

            <!-- <div class="flex justify-end mb-4">
                <button @click="setTheme('light')" class="bg-white text-black px-4 py-2 mr-2 rounded">Light Theme</button>
                <button @click="setTheme('dark')" class="bg-black text-white px-4 py-2 rounded">Dark Theme</button>
            </div> -->
        </div>

        <div v-if="profile" class="col-span-4">
            <div class="bg-white dark:bg-slate-700 rounded p-8 mb-4">
                <h2 class="mb-4 text-slate-400 dark:text-slate-300">Роли</h2>

                <template v-for="r in profile.roles" :key="r">
                    <div v-if="profile.role == r" class="flex items-center gap-4 mb-2 text-teal-600 dark:text-teal-400">
                        <IcoBig name="ok" :size="5" /> {{ roles[r] }}
                    </div>
                    <div
                        v-else
                        class="flex items-center gap-4 mb-2 text-slate-400 dark:text-slate-300 hover:text-teal-400 cursor-pointer"
                        @click="changeRole(r)"
                    >
                        <IcoBig name="minus" :size="5" /> {{ roles[r] }}
                    </div>
                </template>
            </div>

            <div v-if="profile.role == 'Cl'" class="bg-white dark:bg-slate-700 rounded p-8 mb-4">
                <h2 class="mb-4 text-slate-400 dark:text-slate-300">API ключ</h2>

                <div class="flex justify-between">
                    <div class="text-teal-600 dark:text-teal-400" v-if="apikey && apikey.length > 10">{{ apikey }}</div>

                    <div class="btn btn-green dark:bg-teal-700 dark:hover:bg-teal-600" @click="generateApiKey()">Сгенерировать API Ключ</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAccountStore } from "@/store/account";
import { useProfileStore } from "@/store/profile";

export default {
    name: "ProfilePage",

    computed: {
        ...mapState(useMainStore, ["s", "roles", "setPopup", "closePopup", "setTheme"]),
        ...mapState(useAccountStore, ["apikey", "getAccount", "generateApiKey"]),
        ...mapState(useProfileStore, ["profile", "getProfile", "changeRole", "logout", "uploadAvatar"]),
    },

    mounted() {
        this.getProfile();
        this.getAccount();
    },
};
</script>
