<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date', 'compact', 'channel', 'tgs']" @update="update" />
        </div>

        <div class="w-full flex">
            <!-- {{ days }} -->

            <div class="tbl w-52 shrink-0 border border-slate-100 rounded overflow-hidden text-sm">
                <div class="bg-white">
                    <div class="flex items-center !h-8 text-slate-500">ДАТА</div>
                    <div class="font-bold bg-cyan-100/50 text-cyan-700">Бюджет c НДС</div>
                    <div class="bb">Просмотры</div>
                    <div class="bb">Клики</div>
                    <div class="bb">Рег с рекламы</div>
                    <div class="bb">РЕГИСТРАЦИЙ ВСЕГО</div>
                    <div class="bb">Рег. (10:00 - 10:00)</div>
                    <div v-if="!s.compact" class="bb text-slate-400">Конверсия посадки</div>
                    <div v-if="!s.compact" class="text-slate-400">Цена реги CPA</div>
                </div>

                <div class="bg-white my-2">
                    <div class="bb">Людей на 1 вебе</div>
                    <div v-if="!s.compact" class="bb">На эфире</div>
                    <div v-if="!s.compact" class="bb">На повторе</div>
                    <div v-if="!s.compact" class="text-slate-400">Доходимость</div>
                </div>

                <div class="bg-white my-2">
                    <div class="bb">Людей на 2 вебе</div>
                    <div v-if="!s.compact" class="bb">На эфире</div>
                    <div v-if="!s.compact" class="bb">На повторе</div>
                    <div v-if="!s.compact" class="bb text-slate-400">Переходимость</div>
                    <div class="bb">Кол-во заказов</div>
                    <div v-if="!s.compact" class="text-slate-400">Конверсия в заказ</div>
                </div>

                <div class="bg-white my-2">
                    <div class="bb">Людей на 3 вебе</div>
                    <div v-if="!s.compact" class="bb">На эфире</div>
                    <div v-if="!s.compact" class="bb">На повторе</div>
                    <div v-if="!s.compact" class="bb text-slate-400">Переходимость</div>
                    <div class="bb">Кол-во заказов</div>
                    <div v-if="!s.compact" class="text-slate-400">Конверсия в заказ</div>
                </div>

                <div class="bg-white my-2">
                    <div class="bb">Заказов с хвоста</div>
                    <div v-if="!s.compact" class="bb">Коэффициент с хвоста</div>

                    <div class="bb font-bold">Всего заказов</div>
                    <div v-if="!s.compact" class="bb text-slate-400">% заказов от регистраций</div>
                    <div class="bb">Средний чек</div>
                    <div class="font-bold bg-yellow-200/50">Заказов на сумму</div>
                </div>

                <div class="bg-white my-2">
                    <div v-if="!s.compact" class="bb text-slate-400">Конверсия в оплату</div>
                    <div class="bb">Количество покупок</div>
                    <div class="font-bold bg-yellow-200/50">Выручка марафона</div>
                    <div v-if="!s.compact" class="bb text-slate-400">% покупок от регистраций</div>
                </div>

                <div class="bg-white">
                    <div class="font-bold bg-teal-200/50">ROMI</div>
                </div>
            </div>

            <div v-if="funnel" class="w-[calc(100vw-368px)] flex gap-2 overflow-x-scroll snap-x scroll-pl-2 scroll-smooth pl-2 pr-4">
                <div
                    v-for="day in funnel"
                    :key="day.date"
                    class="tbl snap-start w-28 h-fit shrink-0 border border-slate-100 rounded overflow-hidden text-sm text-right"
                    :class="{
                        'border !border-pink-300': [6, 7].includes(moment(day.date).isoWeekday()),
                        'border !border-teal-300': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                        'border !border-slate-300 ': !day.date && day.name,
                    }"
                >
                    <div class="bg-white" :class="{ '!bg-slate-100': !day.date && day.name }">
                        <template v-if="day.date">
                            <div
                                class="flex justify-end items-center !h-8 text-slate-500"
                                :class="{
                                    ' text-pink-500 bg-pink-50': [6, 7].includes(moment(day.date).isoWeekday()),
                                    ' text-teal-500 bg-teal-100': moment(day.date).format('Y-M-D') == moment().format('Y-M-D'),
                                }"
                            >
                                {{ moment(day.date).format("DD") }}&nbsp;{{ month[parseInt(moment(day.date).format("MM"))] }}
                            </div>
                        </template>

                        <template v-if="!day.date && day.name">
                            <div class="flex justify-end items-center !h-8 text-slate-500">{{ day.name }}</div>
                        </template>

                        <div
                            class="flex justify-between items-center font-bold bg-cyan-100/50 text-cyan-700 cursor-pointer hover:text-cyan-500"
                            @click="getClients({ srt: day.date, end: day.date })"
                        >
                            <IconClick :size="3" />{{ parseInt(day.spent_nds).toLocaleString() }}
                        </div>

                        <div class="bb">{{ parseInt(day.views).toLocaleString() }}</div>
                        <div class="bb">{{ parseInt(day.clicks).toLocaleString() }}</div>
                        <div class="bb">{{ day.regs_ad }}</div>
                        <div class="bb">{{ day.regs }}</div>
                        <div class="bb">{{ day.regs10 }}</div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseInt(day.regs / (day.clicks / 100)) }}%</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ Math.round(day.cpa).toLocaleString() }}</div>
                    </div>

                    <div class="bg-white my-2" :class="{ '!bg-slate-100': !day.date && day.name }">
                        <div class="bb">{{ day.web1.total }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web1.live }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web1.repeat }}</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ parseFloat(day.web1.come).toFixed(2) }}%</div>
                    </div>

                    <div class="bg-white my-2" :class="{ '!bg-slate-100': !day.date && day.name }">
                        <div class="bb">{{ day.web2.total }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web2.live }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web2.repeat }}</div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.web2.come).toFixed(2) }}%</div>
                        <div class="bb">{{ day.web2.orders }}</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ parseFloat(day.web2.orders_cr).toFixed(2) }}%</div>
                    </div>

                    <div class="bg-white my-2" :class="{ '!bg-slate-100': !day.date && day.name }">
                        <div class="bb">{{ day.web3.total }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web3.live }}</div>
                        <div v-if="!s.compact" class="bb">{{ day.web3.repeat }}</div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.web3.come).toFixed(2) }}%</div>
                        <div class="bb">{{ day.web3.orders }}</div>
                        <div v-if="!s.compact" class="text-slate-400">{{ parseFloat(day.web3.orders_cr).toFixed(2) }}%</div>
                    </div>

                    <div class="bg-white my-2" :class="{ '!bg-slate-100': !day.date && day.name }">
                        <div class="bb">{{ day.ordersDozim }}</div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.ordersDozim_cr).toFixed(2) }}%</div>

                        <!-- Всего заказов -->
                        <div
                            class="flex justify-between items-center bb font-bold hover:bg-cyan-100/50 text-cyan-700 cursor-pointer hover:text-cyan-500"
                            @click="getOrders({ srt: day.date, end: day.date })"
                        >
                            <IconClick :size="3" /> {{ day.orders }}
                        </div>
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.cpo).toFixed(2) }}%</div>
                        <div class="bb">{{ parseInt(day.avg).toLocaleString() }}</div>
                        <div class="font-bold bg-yellow-200/50">{{ parseInt(day.orders_sum).toLocaleString() }}</div>
                    </div>

                    <div class="bg-white my-2" :class="{ '!bg-slate-100': !day.date && day.name }">
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.pay_cr).toFixed(2) }}%</div>
                        <div
                            class="flex justify-between items-center hover:bg-cyan-200 text-cyan-700 cursor-pointer hover:text-cyan-500"
                            @click="getPays({ srt: day.date, end: day.date })"
                        >
                            <IconClick :size="3" />{{ day.pays }}
                        </div>
                        <div class="font-bold bg-yellow-200/50">{{ parseInt(day.pays_sum).toLocaleString() }}</div>
                        <!-- Процент купивших от регистраций -->
                        <div v-if="!s.compact" class="bb text-slate-400">{{ parseFloat(day.pay_10).toFixed(2) }}%</div>
                    </div>

                    <div class="bg-white">
                        <!-- ROMI -->
                        <div class="font-bold bg-teal-200/50">{{ parseFloat(day.romi).toFixed(2) }}%</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="s.popup == 'orders'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="grid grid-cols-9 items-center bg-white gap-4 p-1 text-xs text-slate-500 border-b border-slate-200 uppercase">
            <div># ЗАКАЗА</div>
            <div>USER ID</div>
            <div class="col-span-3">НАЗВАНИЕ</div>
            <div class="text-right pr-2">ЦЕНА</div>
            <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                <div>source</div>
                <div>medium</div>
                <div>campaign</div>
                <div>content</div>
                <div>term</div>
            </div>
        </div>

        <div v-for="order in orders" :key="order">
            <div class="grid grid-cols-9 items-center gap-2 border-b border-slate-200 py-1 px-2 rounded text-xs hover:bg-cyan-100/50">
                <div class="flex flex-col justify-center">
                    <div># {{ order.number }}</div>
                    <div>{{ moment(order.create_at).format("DD.MM HH:mm") }}</div>
                </div>
                <div class="text-sm">{{ order.user_id }}</div>
                <div class="text-sm col-span-3">{{ order.title }}</div>
                <div class="text-sm text-right pr-2">{{ order.amount }}</div>
                <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                    <div>{{ order.utm_source }}</div>
                    <div>{{ order.utm_medium }}</div>
                    <div>{{ order.utm_campaign }}</div>
                    <div>{{ order.utm_content }}</div>
                    <div>{{ order.utm_term }}</div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="s.popup == 'pays'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="grid grid-cols-12 items-center bg-white gap-4 p-1 text-xs text-slate-500 border-b border-slate-200 uppercase">
            <div># ЗАКАЗА</div>
            <div class="col-span-2">USER</div>
            <div class="col-span-3">НАЗВАНИЕ</div>
            <div class="text-right pr-2">ЦЕНА</div>
            <div class="text-right pr-2">КОМИССИЯ</div>
            <div class="text-right pr-2">ПОЛУЧЕНО</div>
            <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                <div>source</div>
                <div>medium</div>
                <div>campaign</div>
                <div>content</div>
                <div>term</div>
            </div>
        </div>

        <div v-for="pay in pays" :key="pay">
            <div class="grid grid-cols-12 items-center gap-2 border-b border-slate-200 py-1 px-2 rounded text-xs hover:bg-cyan-100/50">
                <div class="flex flex-col justify-center">
                    <div># {{ pay.order_id }}</div>
                    <div>{{ moment(pay.create_at).format("DD.MM HH:mm") }}</div>
                </div>
                <div class="flex flex-col justify-center col-span-2">{{ pay.user_id }} {{ pay.email }}</div>
                <div class="text-sm col-span-3">{{ pay.title }}</div>
                <div class="text-sm text-right pr-2">{{ pay.total }} {{ pay.currency }}</div>
                <div class="text-sm text-right pr-2">{{ pay.commission }} {{ pay.currency }}</div>
                <div class="text-sm text-right pr-2">{{ pay.amount }} {{ pay.currency }}</div>
                <div class="grid grid-cols-5 items-center gap-2 text-center col-span-3">
                    <div>{{ pay.utm_source }}</div>
                    <div>{{ pay.utm_medium }}</div>
                    <div>{{ pay.utm_campaign }}</div>
                    <div>{{ pay.utm_content }}</div>
                    <div>{{ pay.utm_term }}</div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="s.popup == 'clients'"
        class="fixed left-1/2 -translate-x-1/2 top-20 max-h-[calc(100vh-200px)] h-fit w-5/6 rounded z-50 p-4 bg-white overflow-y-scroll"
    >
        <div class="text-center my-4 text-slate-500 uppercase">Расход на рекламу по каждому кабинету (без НДС)</div>
        <div class="grid grid-cols-11 items-center bg-white gap-4 p-1 mt-4 text-xs text-slate-500 border-b border-slate-200">
            <div>КАНАЛ</div>
            <div>АГЕНТСТВО</div>
            <div class="col-span-2">ID</div>
            <div class="col-span-2">КАБИНЕТ</div>
            <div class="text-right">БЮДЖЕТ</div>
            <div class="text-right">ПОКАЗЫ</div>
            <div class="text-right">КЛИКИ</div>
            <div class="text-right text-slate-500">CPM</div>
            <div class="text-right text-slate-500">CPA</div>
        </div>

        <div v-for="item in clients" :key="item.id" class="grid grid-cols-11 items-center bg-white gap-4 p-1 text-sm border-b border-slate-200">
            <a v-if="item.channel_id == 1" class="text-2xl text-cyan-600 font-bold cursor-pointer"><IVk /></a>
            <a v-if="item.channel_id == 2" class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"><IVkAds :size="6" /></a>
            <a v-if="item.channel_id == 3" class="text-2xl text-blue-500 font-bold cursor-pointer"><IVk /></a>
            <a v-if="item.channel_id == 4" class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"><IFb :size="6" /></a>
            <a v-if="item.channel_id == 5" class="text-2xl text-cyan-600 font-bold cursor-pointer p-1"><IYa :size="6" /></a>

            <div>{{ item.label }}</div>
            <div class="col-span-2 text-slate-500">{{ item.id }}</div>
            <div class="col-span-2">{{ item.name }}</div>
            <div class="text-right tabular-nums">{{ parseInt(item.spent).toLocaleString() }} ₽</div>
            <div class="text-right tabular-nums">{{ parseInt(item.views).toLocaleString() }}</div>
            <div class="text-right tabular-nums">{{ parseInt(item.clicks).toLocaleString() }}</div>
            <div v-if="item.views > 0" class="text-right tabular-nums text-slate-500">
                {{ parseInt((item.spent * 1000) / item.views)?.toLocaleString() }} ₽
            </div>
            <div v-else class="text-right tabular-nums text-slate-500">-</div>
            <div v-if="item.clicks > 0" class="text-right tabular-nums text-slate-500">
                {{ parseInt(item.spent / item.clicks)?.toLocaleString() }} ₽
            </div>
            <div v-else class="text-right tabular-nums text-slate-500">-</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";

import FilterComponent from "@/components/Filter.vue";

import moment from "moment";

export default {
    name: "DaysPage",

    components: { FilterComponent },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "month", "profile", "setPeriod", "setMonth", "setSettings"]),
        ...mapState(useClientStore, ["days", "orders", "pays", "clients", "getDays", "getOrders", "getPays", "getClients"]),

        // funnel() {
        //     return {};
        // },

        funnel() {
            if (!this.days) return {};

            let data = [];
            let week = {
                date: null,
                web1: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
                web2: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
                web3: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
                views: 0,
                clicks: 0,
                spent_nds: 0,
                regs_ad: 0,
                regs: 0,
                regs10: 0,
                ordersDozim: 0,
                orders: 0,
                orders_sum: 0,
                pays: 0,
                pays_sum: 0,
                romi: 0,
            };

            this.days.forEach((day) => {
                if (this.s.days) data.push(day);

                week.views = parseInt(week.views) + parseInt(day.views);
                week.clicks = parseInt(week.clicks) + parseInt(day.clicks);
                week.spent_nds = parseFloat(week.spent_nds + day.spent_nds);
                week.regs_ad = parseInt(week.regs_ad) + parseInt(day.regs_ad);
                week.regs = parseInt(week.regs) + parseInt(day.regs);
                week.regs10 = parseInt(week.regs10) + parseInt(day.regs10);

                week.web1.total = parseInt(week.web1.total) + parseInt(day.web1.total);
                week.web1.live = parseInt(week.web1.live) + parseInt(day.web1.live);
                week.web1.repeat = parseInt(week.web1.repeat) + parseInt(day.web1.repeat);
                week.web1.come = parseInt(week.web1.come) + parseInt(day.web1.come);

                week.web2.total = parseInt(week.web2.total) + parseInt(day.web2.total);
                week.web2.live = parseInt(week.web2.live) + parseInt(day.web2.live);
                week.web2.repeat = parseInt(week.web2.repeat) + parseInt(day.web2.repeat);
                week.web2.come = parseInt(week.web2.come) + parseInt(day.web2.come);
                week.web2.orders = parseInt(week.web2.orders) + parseInt(day.web2.orders);

                week.web3.total = parseInt(week.web3.total) + parseInt(day.web3.total);
                week.web3.live = parseInt(week.web3.live) + parseInt(day.web3.live);
                week.web3.repeat = parseInt(week.web3.repeat) + parseInt(day.web3.repeat);
                week.web3.come = parseInt(week.web3.come) + parseInt(day.web3.come);
                week.web3.orders = parseInt(week.web3.orders) + parseInt(day.web3.orders);

                week.ordersDozim = parseInt(week.ordersDozim) + parseInt(day.ordersDozim);

                week.orders = parseInt(week.orders) + parseInt(day.orders);
                week.orders_sum = parseInt(week.orders_sum) + parseInt(day.orders_sum);

                week.pays = parseInt(week.pays) + parseInt(day.pays);
                week.pays_sum = parseInt(week.pays_sum) + parseInt(day.pays_sum);

                week.romi = parseInt(week.romi) + parseInt(day.romi);

                // итог за неделю
                if (moment(day.date).isoWeekday() == 7) {
                    week.name = "НЕДЕЛЯ " + moment(day.date).isoWeek();
                    week.cpm = parseFloat((week.spent_nds / week.views) * 1000).toFixed(2);
                    week.ctr = parseFloat(week.clicks / (week.views / 100)).toFixed(2);
                    week.cpc = parseInt(week.spent_nds / week.clicks);
                    week.cpa = parseInt(week.spent_nds / week.regs10);

                    week.web1.come = parseFloat(week.web1.come / 7).toFixed(2);
                    week.web2.come = parseFloat(week.web2.come / 7).toFixed(2);
                    week.web3.come = parseFloat(week.web3.come / 7).toFixed(2);

                    week.web2.orders_cr = parseFloat((week.web2.orders / week.web2.live) * 100).toFixed(2);
                    week.web3.orders_cr = parseFloat((week.web3.orders / week.web3.live) * 100).toFixed(2);

                    week.ordersDozim_cr = (week.ordersDozim / (week.web2.orders + week.web3.orders)) * 100;

                    week.cpo = parseFloat(week.orders / week.regs10).toFixed(2);
                    week.avg = parseFloat(week.orders_sum / week.orders).toFixed(2);
                    week.pay_cr = parseFloat(week.pays / week.orders).toFixed(2);
                    week.pay_10 = parseFloat(week.pays / week.regs10).toFixed(2);

                    week.romi = parseFloat(week.romi / 7).toFixed(2);

                    data.push(week);

                    week = {
                        date: null,
                        web1: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
                        web2: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
                        web3: { total: 0, live: 0, repeat: 0, come: 0, orders: 0 },
                        views: 0,
                        clicks: 0,
                        spent_nds: 0,
                        regs_ad: 0,
                        regs: 0,
                        regs10: 0,
                        ordersDozim: 0,
                        orders: 0,
                        orders_sum: 0,
                        pays: 0,
                        pays_sum: 0,
                        romi: 0,
                    };
                }
            });

            return data;
        },
    },

    methods: {
        update() {
            this.getDays();
        },
    },

    mounted() {
        this.getDays();
    },
};
</script>

<style>
.tbl div div {
    padding: 2px 8px;
    overflow: hidden;
    /* border-bottom: #eee solid 1px; */
}
.bb {
    border-bottom: #eee solid 1px;
}
</style>
