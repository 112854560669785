<template>
    <div class="h-screen min-h-screen flex justify-center items-center">
        <div class="box gradient max-w-md w-full p-10 mb-20">
            <div class="text-center">
                <div class="flex justify-center text-lg uppercase font-latobold">
                    <!-- <div class="text-gray-400 cursor-pointer mr-4" :class="{ '!text-sky-600': form == 'login' }" @click="form = 'login'">Вход</div> -->
                    <!-- <div class="text-gray-400 cursor-pointer" :class="{ '!text-sky-600': form == 'reg' }" @click="form = 'reg'">Регистрация</div> -->
                </div>
            </div>

            <form class="p-4">
                <div v-if="picture" class="relative group">
                    <div class="absolute top-0 right-0 text-slate-500 group-hover:text-sky-400" @click="removeUsername()"><IconClose /></div>
                    <img class="w-full h-full object-cover" :src="'https://api.ulangroup.ru/users/' + picture" />
                </div>

                <input
                    :class="{ '!hidden': usernameSaved }"
                    type="text"
                    class="text-slate-500 gradient outline-0 w-full border px-5 h-9 mb-4 text-sm rounded"
                    v-model="username"
                    placeholder="Логин"
                />

                <input
                    type="password"
                    autocomplete="on"
                    class="text-slate-500 gradient outline-0 w-full border px-5 h-9 mb-4 text-sm rounded"
                    v-model.trim="password"
                    placeholder="Пароль"
                    @keyup.enter.stop="login({ username: username, password: password })"
                />

                <div class="mx-auto block bg-sky-700 hover:bg-sky-600 rounded px-5 py-4 h-12 cursor-pointer">
                    <div
                        class="text-sm font-latobold leading-none uppercase text-white text-center cursor-pointer"
                        @click="login({ username: username, password: password })"
                    >
                        Войти
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useProfileStore } from "@/store/profile";

export default {
    name: "LoginPage",

    data() {
        return {
            username: "",
            usernameSaved: "",
            password: "",
            picture: "",
        };
    },

    computed: {
        ...mapState(useProfileStore, ["login"]),
    },

    methods: {
        removeUsername() {
            this.username = null;
            this.usernameSaved = null;
            localStorage.removeItem("picture");
            localStorage.removeItem("username");
        },
    },

    mounted() {
        if (localStorage.getItem("picture") && localStorage.getItem("username")) {
            this.username = localStorage.getItem("username");
            this.usernameSaved = localStorage.getItem("username");
            this.picture = localStorage.getItem("picture");
        }
    },
};
</script>
