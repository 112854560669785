<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date', 'channel', 'compact', 'utms', 'targetologs']" @update="update" />
        </div>

        <div class="w-full px-4" v-if="dashboard">
            <!-- ИТОГ -->
            <div class="w-full h-32 flex justify-center items-center gap-10 text-white my-12">
                <div class="w-fit h-32 flex flex-col text-center relative fly2 f p-4">
                    <span class="title">РАСХОД</span>
                    <span class="num text-pink-500">
                        <span class="latobold"> {{ parseInt(funnel?.spent).toLocaleString() }}</span>
                        <sup> ₽</sup>
                    </span>
                    <StatsCard circleColor="rgba(220, 40, 180, 0.05)" />
                </div>

                <div class="w-12 pb-20 flex flex-col justify-center items-center">
                    <span class="title">ROMI</span>
                    <span class="text-xl text-slate-500">{{ parseInt(funnel?.romi) }}%</span>
                </div>

                <div class="w-fit h-32 flex flex-col text-center relative fly3 f p-4">
                    <span class="title">ОПЛАТЫ</span>
                    <span class="num text-teal-500">
                        <span class="latobold"> {{ parseInt(funnel?.payments).toLocaleString() }}</span>
                        <sup> ₽</sup>
                    </span>
                    <StatsCard circleColor="rgba(10, 210, 230, 0.05)" />
                </div>
            </div>

            <!-- ВОРОНКА -->
            <!-- <h2 class="text-slate-400 text-sm text-center mt-4">ВОРОНКА</h2> -->

            <div class="flex justify-between gap-2 h-28 my-4 rounded">
                <div class="flex flex-col justify-center items-center">
                    <span class="title">РАСХОД</span>
                    <span class="num text-xl my-1 text-pink-500">{{ parseInt(funnel?.spent).toLocaleString() }} <sup> ₽</sup></span>
                    <span class="title">CPAD {{ parseInt(funnel?.cpad).toLocaleString() }} ₽</span>
                </div>

                <div v-if="!s.compact" class="flex flex-col justify-center items-center relative">
                    <span class="title">ПРОСМОТРЫ</span>
                    <span class="num text-xl my-1 text-pink-500">{{ parseInt(funnel?.views).toLocaleString() }}</span>
                    <span class="title">CPM {{ parseInt(funnel?.cpm).toLocaleString() }} ₽</span>
                </div>

                <div class="flex flex-col justify-center items-center relative">
                    <span class="title">КЛИКИ</span>
                    <span class="num text-xl my-1 text-pink-500">{{ parseInt(funnel?.clicks).toLocaleString() }}</span>
                    <span class="title">CPC {{ funnel?.cpc }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.click }}%
                    </div>
                </div>

                <div
                    @click="setPopup('userList')"
                    class="flex flex-col justify-center items-center relative group cursor-pointer hover:bg-white rounded p-2 duration-300"
                >
                    <span class="title">ЛИДЫ</span>
                    <span class="num text-xl my-1 text-cyan-500 duration-300">{{ parseInt(funnel?.regs).toLocaleString() }}</span>
                    <span class="title">CPL {{ funnel?.cpl }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.reg }}%
                    </div>
                </div>

                <div v-if="!s.compact" class="flex flex-col justify-center items-center relative">
                    <span class="title">ВЕБИНАР 1</span>
                    <span class="num text-xl my-1 text-cyan-500">{{ parseInt(funnel?.web1).toLocaleString() }}</span>
                    <span class="title">CPV {{ funnel?.cpv1 }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.web1 }}%
                    </div>
                </div>

                <div
                    @click="setPopup('orderList')"
                    class="flex flex-col justify-center items-center relative group cursor-pointer hover:bg-white rounded p-2 duration-300"
                >
                    <span class="title">ЗАКАЗЫ</span>
                    <span class="num text-xl my-1 text-cyan-500 duration-300">{{ funnel?.orderc }}</span>
                    <span class="title">CPO {{ funnel?.cpo }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.order }}%
                    </div>
                </div>

                <div v-if="!s.compact" class="flex flex-col justify-center items-center group cursor-pointer">
                    <span class="title">СУММА ЗАКАЗОВ</span>
                    <span class="num text-xl my-1 text-cyan-500 duration-300">{{ parseInt(funnel?.orders).toLocaleString() }} <sup> ₽</sup></span>
                    <span class="title">СРЕДНИЙ ЧЕК {{ parseInt(funnel?.orders / funnel?.orderc).toLocaleString() }} ₽</span>
                </div>

                <div
                    @click="setPopup('paymentList')"
                    class="flex flex-col justify-center items-center relative group cursor-pointer hover:bg-white rounded p-2 duration-300"
                >
                    <span class="title">ОПЛАТЫ</span>
                    <span class="num text-xl my-1 text-teal-500 duration-300">{{ funnel?.paymentc }}</span>
                    <span class="title">CAC {{ funnel?.cac }} ₽</span>
                    <div class="absolute -bottom-4 -left-14 flex justify-center items-center rounded-full p-1 px-2 bg-slate-200 title">
                        {{ conversion.payment }}%
                    </div>
                </div>

                <div class="flex flex-col justify-center items-center group cursor-pointer">
                    <span class="title">СУММА ОПЛАТ</span>
                    <span class="num text-xl my-1 text-teal-500 duration-300">{{ parseInt(funnel?.payments).toLocaleString() }} <sup> ₽</sup></span>
                    <span class="title">СРЕДНИЙ ЧЕК {{ parseInt(funnel?.payments / funnel?.paymentc).toLocaleString() }} ₽</span>
                </div>

                <div class="flex flex-col justify-center items-center">
                    <span class="title">РЕЗУЛЬТАТ</span>
                    <span class="num text-xl my-1 text-teal-500">{{ parseFloat(funnel?.romi / 100).toFixed(1) }}x</span>
                    <span class="title"> ROMI {{ parseInt(funnel?.romi).toLocaleString() }} % </span>
                </div>
            </div>

            <!-- <div class="grid grid-cols-5 gap-8">
                    <div class="fly2 f p-4">
                        <StatsCard :title="'vk'" :spent="parseInt(counter_vk)" circleColor="rgba(60, 160, 255, 0.05)" />
                    </div>

                    <div class="fly3 f p-4">
                        <StatsCard :title="'vkads'" :spent="parseInt(counter_vkads)" circleColor="rgba(0, 210, 230, 0.05)" />
                    </div>

                    <div class="fly2 f p-4">
                        <StatsCard :title="'market'" :spent="parseInt(counter_market)" circleColor="rgba(220, 40, 180, 0.05)" />
                    </div>

                    <div class="fly4 f p-4">
                        <StatsCard :title="'book'" :spent="parseInt(counter_facebook)" circleColor="rgba(80, 100, 255, 0.05)" />
                    </div>

                    <div class="fly3 f p-4">
                        <StatsCard :title="'yandex'" :spent="parseInt(counter_yandex)" circleColor="rgba(255, 220, 40, 0.08)" />
                    </div>
                </div> -->

            <!-- {{ grafs }} -->
            <h2 class="text-slate-400 text-sm text-center mt-10 mb-4">ГРАФИК РАСХОДА</h2>
            <template v-if="grafs?.length != 0">
                <MultiGrafAnimated :red="grafs?.spent" :blue="grafs?.regs" :green="grafs?.payments" name="spent" h="h-48" />
            </template>

            <!-- <h2 class="text-slate-400 text-sm text-center mt-20">ГРАФИК ЛИДОВ</h2>
            <div v-if="grafs?.spent" class="w-full"><LineGraf :graf="grafs?.spent" name="regs" h="h-48" /></div> -->

            <h2 class="text-slate-400 text-sm text-center mt-20 mb-8">КАНАЛЫ</h2>

            <div class="w-full flex flex-wrap justify-center items-center gap-20 px-20">
                <template v-for="(channel, title) in channels" :key="channel">
                    <div v-if="channel?.spent > 0 || channel?.payments > 0" :class="['relative', 'f', `fly${Math.floor(Math.random() * 3) + 1}`]">
                        <StatsCardMini :title="title" :data="channel" />
                    </div>
                </template>

                <!-- <div class="fly2 f" v-if="channels?.vk && channels?.vk?.spent">
                    <StatsCardMini
                        :title="'vk'"
                        :spent="parseInt(counter_vk)"
                        :payments="channels?.vk.payments"
                        circleColor="rgba(60, 160, 255, 0.05)"
                    />
                </div>

                <div class="fly3 f" v-if="channels?.vkads && channels?.vkads?.spent">
                    <StatsCardMini
                        :title="'vkads'"
                        :spent="parseInt(counter_vkads)"
                        :payments="channels?.vkads.payments"
                        circleColor="rgba(0, 210, 230, 0.05)"
                    />
                </div>

                <div class="fly2 f" v-if="channels?.market && channels?.market?.spent">
                    <StatsCardMini
                        :title="'market'"
                        :spent="parseInt(counter_market)"
                        :payments="channels?.market.payments"
                        circleColor="rgba(220, 40, 180, 0.05)"
                    />
                </div>

                <div class="fly4 f" v-if="channels?.facebook && channels?.facebook?.spent">
                    <StatsCardMini
                        :title="'book'"
                        :spent="parseInt(counter_facebook)"
                        :payments="channels?.facebook.payments"
                        circleColor="rgba(80, 100, 255, 0.05)"
                    />
                </div>

                <div class="fly3 f" v-if="channels?.yandex && channels?.yandex?.spent">
                    <StatsCardMini
                        :title="'yandex'"
                        :spent="parseInt(counter_yandex)"
                        :payments="channels?.yandex.payments"
                        circleColor="rgba(255, 220, 40, 0.08)"
                    />
                </div>

                <div class="fly3 f" v-if="channels?.tgads && channels?.tgads?.spent">
                    <StatsCardMini
                        :title="'tgads'"
                        :spent="parseInt(counter_tgads)"
                        :payments="channels?.tgads.payments"
                        circleColor="rgba(40, 255, 80, 0.08)"
                    />
                </div> -->
            </div>

            <!-- ТАБЛИЦА КАНАЛОВ -->
            <h2 class="text-slate-400 text-sm text-center mt-20 mb-2">ВОРОНКА ПО КАНАЛАМ</h2>

            <div class="grid grid-cols-11 items-center gap-2 text-right text-xs text-slate-400">
                <span></span>
                <span>РАСХОД</span>
                <!-- <span>ОБЪЯВЛЕНИЯ</span> -->
                <span>ПРОСМОТРЫ</span>
                <span>КЛИКИ</span>
                <span>ЛИДЫ</span>
                <span>ВЕБ</span>
                <span>ЗАКАЗЫ</span>
                <span>СУММА&nbsp;ЗАКАЗОВ</span>
                <span class="pr-2">ОПЛАТЫ</span>
                <span class="pr-2">СУММА&nbsp;ОПЛАТ</span>
                <span class="pr-4">ROMI</span>
            </div>

            <div v-for="(channel, title) in channels" :key="channel" class="w-full rounded-xl my-1 text-slate-500 hover:my-2 duration-500">
                <ChannelTable :title="title" :channel="channel" />
            </div>

            <!-- <div class="flex flex-col justify-center items-center rounded p-4 mt-8">
                    <h2 class="text-slate-400 text-sm">UTM TERM</h2>

                    <div class="grid grid-cols-5 items-center gap-4 p-2 w-full text-slate-400 text-xs">
                        <div>КАНАЛ</div>
                        <div>НАЗВАНИЕ</div>
                        <div class="text-right">РАСХОД</div>
                        <div class="text-right">ПРОСМОТРЫ</div>
                        <div class="text-right">КЛИКИ</div>
                    </div>

                    <div v-for="item in dash?.utm_term" :key="item" class="py-px w-full group hover:py-1 relative duration-500">
                        <div
                            class="grid grid-cols-5 items-center gap-4 bg-white/50 text-slate-500 hover:bg-cyan-50 hover:text-cyan-600 p-2 w-full rounded"
                        >
                            <div>{{ item.channel }}</div>
                            <div>{{ item.name }}</div>
                            <div class="text-right tabular-nums">{{ parseInt(item.spent).toLocaleString() }}</div>
                            <div class="text-right tabular-nums">{{ parseInt(item.views).toLocaleString() }}</div>
                            <div class="text-right tabular-nums">{{ parseInt(item.clicks).toLocaleString() }}</div>
                        </div>
                    </div>
                </div> -->

            <template v-if="dashboard?.targetologs">
                <h2 class="text-slate-400 text-sm text-center mt-10 mb-4">ТАРГЕТОЛОГИ</h2>

                <div class="grid grid-cols-11 items-center gap-2 text-right text-xs text-slate-400">
                    <span></span>
                    <span>РАСХОД</span>
                    <!-- <span>ОБЪЯВЛЕНИЯ</span> -->
                    <span>ПРОСМОТРЫ</span>
                    <span>КЛИКИ</span>
                    <span>ЛИДЫ</span>
                    <span>ВЕБ</span>
                    <span>ЗАКАЗЫ</span>
                    <span>СУММА&nbsp;ЗАКАЗОВ</span>
                    <span class="pr-2">ОПЛАТЫ</span>
                    <span class="pr-2">СУММА&nbsp;ОПЛАТ</span>
                    <span class="pr-4">ROMI</span>
                </div>

                <div
                    v-for="tg in dashboard?.targetologs"
                    :key="tg"
                    class="w-full rounded-xl group relative my-1 text-slate-500 hover:my-2 duration-500"
                >
                    <div class="w-full absolute bottom-0 left-1 z-20">
                        <img
                            v-if="tg.pic"
                            :src="'https://statapi.timekraken.ru/uploads/users/mini/' + tg?.pic"
                            class="h-[36px] shrink-0 object-contain duration-500 opacity-50 group-hover:h-[44px] group-hover:opacity-100"
                        />
                        <img
                            v-else
                            :src="'https://statapi.timekraken.ru/uploads/users/u0.png'"
                            class="h-[36px] shrink-0 object-contain duration-500 opacity-30 group-hover:h-[44px]"
                        />
                    </div>

                    <div
                        class="grid grid-cols-11 justify-center items-center gap-2 text-right text-sm relative overflow-hidden bg-white/50 hover:bg-white h-10 rounded-xl"
                    >
                        <div v-if="parseInt(tg?.id)" class="pl-12 text-sm text-slate-400 text-center">{{ tg.utm }}</div>
                        <div v-else class="pl-12 text-sm text-slate-400 text-center">NoName</div>

                        <div class="latobold text-pink-500">{{ parseInt(tg.spent).toLocaleString() }}</div>
                        <!-- <div class="latolight">{{ parseInt(tg.ads || 0).toLocaleString() }}</div> -->
                        <div class="latolight">{{ parseInt(tg.views).toLocaleString() }}</div>
                        <div class="latolight">{{ parseInt(tg.clicks).toLocaleString() }}</div>
                        <div class="latobold text-cyan-500">{{ parseInt(tg.regs).toLocaleString() }}</div>
                        <div class="latolight">{{ parseInt(tg.web1).toLocaleString() }}</div>
                        <div class="latolight">{{ parseInt(tg.orderc).toLocaleString() }}</div>
                        <div class="latolight">{{ parseInt(tg.orders).toLocaleString() }}</div>
                        <div class="latolight pr-2">{{ parseInt(tg.paymentc).toLocaleString() }}</div>
                        <div class="latobold text-teal-500 pr-4">{{ parseInt(tg.payments || 0).toLocaleString() }}</div>

                        <div v-if="tg.spent > 0" class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold">
                            {{ parseInt((tg.payments * 100) / (tg.spent || 1) || 0).toLocaleString() }}
                        </div>
                        <div v-else class="relative top-0 right-4 z-20 h-10 w-full flex justify-end items-center text-white latobold">-</div>

                        <span
                            class="absolute z-10 -right-4 top-1/2 transform -translate-y-1/2 w-20 h-20 rotate-45 rounded-xl group-hover:-right-0 duration-500"
                            :class="{
                                '!bg-cyan-500': parseInt(tg.payments * 100) / tg.spent > 100,
                                '!bg-slate-300': parseInt(tg.payments * 100) / tg.spent <= 100,
                            }"
                        >
                        </span>
                    </div>
                </div>
            </template>

            <!-- <template v-if="dashboard?.targetologs">
                    <h2 class="text-slate-400 text-sm text-center mt-20 mb-4">ТАРГЕТОЛОГИ</h2>

                    <div class="absolute bottom-0 z-50">
                        <img
                            v-if="tg.pic"
                            :src="'https://statapi.timekraken.ru/uploads/users/' + tg.pic"
                            :alt="tg.name"
                            class="w-full h-[88px] object-contain group-hover:h-24 duration-300"
                        />
                        <img
                            :src="'https://statapi.timekraken.ru/uploads/users/u0.png'"
                            class="w-full h-[88px] object-contain group-hover:h-24 duration-300 opacity-30"
                        />
                    </div>

                    <div class="w-full mb-20">
                        <div
                            v-for="tg in dashboard?.targetologs"
                            :key="tg"
                            class="w-full rounded-xl h-16 overflow-hidden relative my-2 text-slate-500 group hover:my-3 duration-500"
                        >
                            <div class="bg-white h-16 rounded-xl grid grid-cols-13 justify-center items-center gap-2 overflow-hidden">
                                <div class="text-xs text-slate-100 p-2">{{ tg.tg_id }}</div>
                                <div v-if="tg.tg_id" class="text-xl leading-3 text-center">{{ tg.utm }}</div>
                                <div v-else class="text-xl leading-3 text-center">NoName</div>

                                <div class="text-center">
                                    <div class="latobold leading-3 mt-2 text-cyan-500">{{ parseInt(tg.spent).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">РАСХОД</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.ads).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">ОБЪЯВЛЕНИЯ</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.views).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">ПРОСМОТРЫ</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.clicks).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">КЛИКИ</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.regs).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">ЛИДЫ</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.web1).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">ВЕБ 1</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.orderc).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">ЗАКАЗЫ</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.orders).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">СУММА&nbsp;ЗАКАЗОВ</span>
                                </div>

                                <div class="text-center">
                                    <div class="leading-3 mt-2">{{ parseInt(tg.paymentc).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">ОПЛАТЫ</span>
                                </div>

                                <div class="text-center">
                                    <div class="latobold leading-3 mt-2 text-cyan-500">{{ parseInt(tg.payments).toLocaleString() }}</div>
                                    <span class="text-[8px] text-slate-400">СУММА&nbsp;ОПЛАТ</span>
                                </div>

                                <div class="h-16 relative text-white">
                                    <span class="absolute top-5 right-2 z-20">
                                        {{ parseInt((tg.payments * 100) / tg.spent).toLocaleString() }}
                                    </span>
                                    <span class="absolute top-11 right-3 z-20 text-[8px]"> ROMI </span>
                                </div>
                                <span
                                    class="absolute -right-4 top-1/2 transform -translate-y-1/2 w-24 h-24 bg-cyan-500 rotate-45 rounded-xl"
                                    :class="{ '!bg-pink-500': (tg.payments * 100) / tg.spent <= 100 }"
                                >
                                </span>
                            </div>
                        </div>
                    </div>
                </template> -->
        </div>
    </div>

    <template v-if="s.popup == 'userList' && dashboard?.payments"><UserList /></template>
    <template v-if="s.popup == 'userInfo' && user?.id"><UserInfo /></template>
    <template v-if="s.popup == 'orderList' && dashboard?.orders"><OrderList /></template>
    <template v-if="s.popup == 'paymentList' && dashboard?.payments"><PaymentList /></template>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";
import MultiGrafAnimated from "../components/Graf/MultiGrafAnimated.vue";
import StatsCard from "@/components/StatsCard.vue";
import StatsCardMini from "@/components/StatsCardMini.vue";
import ChannelTable from "@/components/ChannelTable.vue";

import UserList from "../components/UserList.vue";
import UserInfo from "../components/UserInfo.vue";
import OrderList from "../components/OrderList.vue";
import PaymentList from "../components/PaymentList.vue";

export default {
    name: "ClientDashboard",

    components: { FilterComponent, MultiGrafAnimated, StatsCard, StatsCardMini, ChannelTable, UserList, UserInfo, OrderList, PaymentList },

    data() {
        return {
            moment: moment,

            counter_total: 0,
            counter_vk: 0,
            counter_vkads: 0,
            counter_market: 0,
            counter_facebook: 0,
            counter_yandex: 0,
            counter_tgads: 0,
            animationInterval: null,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile", "setPopup"]),
        ...mapState(useClientStore, ["project", "dashboard", "user", "getDashboard"]),

        funnel() {
            if (!this.dashboard) return [];

            let data = { ...this.dashboard.total, romi: 0, cpm: 0, cpc: 0, cpl: 0, ctr: 0, cpo: 0, cac: 0 };

            if (this.s.nds) data.spent = data.spent * 1.2;

            data.cpad = parseFloat(data.spent / data.ads).toFixed(2);
            data.cpm = parseFloat((data.spent / data.views) * 1000).toFixed(2);
            data.cpc = parseInt(data.spent / data.clicks);
            data.cpl = parseInt(data.spent / data.regs);
            data.cpv1 = parseInt(data.spent / data.web1);
            data.ctr = parseInt(data.clicks / (data.views / 100));
            data.cpo = parseInt(data.spent / data.orderc);
            data.cac = parseInt(data.spent / data.paymentc);
            data.romi = parseFloat((data.payments * 100) / data.spent).toFixed(2);

            return data;
        },

        conversion() {
            if (!this.dashboard || !this.dashboard.total) return [];

            let data = {};

            data.click = parseFloat((this.dashboard.total.clicks / this.dashboard.total.views) * 100).toFixed(2);
            data.reg = parseFloat((this.dashboard.total.regs / this.dashboard.total.clicks) * 100).toFixed(2);
            data.web1 = parseFloat((this.dashboard.total.web1 / this.dashboard.total.regs) * 100).toFixed(2);
            data.order = parseFloat((this.dashboard.total.orderc / this.dashboard.total.web1) * 100).toFixed(2);
            data.payment = parseFloat((this.dashboard.total.payments / this.dashboard.total.orders) * 100).toFixed(2);

            return data;
        },

        channels() {
            if (!this.dashboard || !this.dashboard?.channels) return [];

            let data = {};

            for (let channel in this.dashboard.channels) {
                data[channel] = { ...this.dashboard.channels[channel] };
                if (this.s.nds) data[channel].spent *= 1.2;
            }

            return data;
        },

        grafs() {
            if (!this.dashboard || !this.dashboard?.grafs) return [];

            let data = JSON.parse(JSON.stringify(this.dashboard.grafs));
            if (this.s.nds && data.spent) for (let item in data.spent) data.spent[item].value *= 1.2;

            return data;
        },

        orders() {
            if (!this.dashboard || !this.dashboard?.orders) return [];

            let data = JSON.parse(JSON.stringify(this.dashboard.grafs));
            if (this.s.nds && data.spent) for (let item in data.spent) data.spent[item].value *= 1.2;

            return data;
        },
    },

    watch: {
        dashboard: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },

        s: {
            handler() {
                this.startAnimation();
            },
            deep: true,
        },
    },

    methods: {
        update() {
            this.getDashboard();
        },

        startAnimation() {
            if (!this.dashboard) return null;

            const start = performance.now();
            const duration = 3000; // Длительность анимации в миллисекундах

            const increments = [
                { key: "total", target: this.dashboard?.funnel?.spent },
                { key: "vk", target: this.channels?.vk?.spent },
                { key: "vkads", target: this.channels?.vkads?.spent },
                { key: "market", target: this.channels?.market?.spent },
                { key: "facebook", target: this.channels?.facebook?.spent },
                { key: "yandex", target: this.channels?.yandex?.spent },
                { key: "tgads", target: this.channels?.tgads?.spent },
            ];

            const animate = (timestamp) => {
                const progress = Math.min((timestamp - start) / duration, 1); // Прогресс анимации от 0 до 1

                for (let i = 0; i < increments.length; i++) {
                    const item = increments[i];
                    const counterName = "counter_" + item.key;
                    let currentValue = this[counterName] || 0;

                    if (currentValue !== item.target) {
                        const diff = item.target - currentValue;
                        const change = diff * (1 - Math.pow(1 - progress, 2)); // Постепенное уменьшение скорости изменения
                        currentValue = currentValue + change; // Изменение значения счетчика

                        if (currentValue < 0 && currentValue >= diff) currentValue = 0;

                        this[counterName] = currentValue; // Присваиваем новое значение счетчику
                    }
                }

                if (progress < 1) requestAnimationFrame(animate);
            };

            requestAnimationFrame(animate);
        },
    },

    mounted() {
        this.getDashboard();
    },
};
</script>

<style scoped>
/* круги у каналов */
.circle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Помещаем контейнер на задний план */
}

.fly2 {
    animation: moveUpDown 6s infinite alternate;
}
.fly3 {
    animation: moveUpDown 8s infinite alternate;
}
.fly4 {
    animation: moveUpDown 10s infinite alternate;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-10px);
    }
}
</style>
