<template>
    <div class="flex flex-col justify-center items-center relative z-50">
        <div v-if="title == 'vk'" class="text-blue-500"><IVk :size="10" /></div>
        <div v-if="title == 'vkads'" class="text-blue-600 p-1"><IVkAds :size="8" /></div>
        <div v-if="title == 'market'" class="text-pink-600"><IVkCamp :size="10" /></div>
        <div v-if="title == 'book'"><IFb :size="10" /></div>
        <div v-if="title == 'yandex'" class="p-1"><IYa :size="8" /></div>
        <div v-if="title == 'tgads'" class="p-1"><ITgads :size="8" /></div>

        <div class="title text-center text-sm opacity-50">{{ title }}</div>
        <div v-if="spent" class="num text-cyan-600" :class="{ '!text-5xl': title === 'ВСЕГО' }">{{ parseInt(spent).toLocaleString() }}</div>

        <template v-if="data">
            <div class="num text-cyan-600" :class="{ '!text-5xl': title === 'ВСЕГО' }">{{ parseInt(data?.payments).toLocaleString() }}</div>
            <div class="num text-cyan-600" :class="{ '!text-5xl': title === 'ВСЕГО' }">{{ parseInt((data.payments * 100) / data.spent) }}%</div>
        </template>

        <div class="flex flex-col justify-between items-center mt-2">
            <div v-if="views" class="grid grid-cols-2 items-center gap-8">
                <span class="flex justify-center items-center text-cyan-600/60 gap-2">
                    <IconEye :size="3" />
                    {{ parseInt(views).toLocaleString() }}
                </span>
                <span class="flex items-center gap-2 text-cyan-600/60">
                    <span class="text-xs">CPM</span>
                    {{ spent !== undefined && views !== 0 ? parseInt((spent * 1000) / views).toLocaleString() : "0" }}
                </span>
            </div>

            <div v-if="clicks" class="grid grid-cols-2 items-center gap-8">
                <span class="flex justify-center items-center text-cyan-600/60 gap-2">
                    <IconClick :size="3" />
                    {{ parseInt(clicks).toLocaleString() }}
                </span>
                <span class="flex items-center gap-2 text-cyan-600/60">
                    <span class="text-xs">CPC</span>
                    {{ spent !== undefined && clicks !== 0 ? parseInt(spent / clicks).toLocaleString() : "0" }}
                </span>
            </div>

            <div v-if="ads" class="flex items-center gap-8">
                <span class="flex justify-center items-center text-cyan-600/60 gap-2">
                    <IVkAd :size="3" />
                    {{ parseInt(ads).toLocaleString() }}
                </span>
                <span class="flex items-center gap-2 text-cyan-600/60">
                    <span class="text-xs">CPAD</span>
                    {{ spent !== undefined && ads !== 0 ? parseInt(spent / ads).toLocaleString() : "0" }}
                </span>
            </div>
        </div>
    </div>

    <div class="circle-container">
        <CircleS v-for="index in 5" :key="index" :color="circleColor" />
    </div>
</template>
<script>
import CircleS from "./circle.vue";

export default {
    components: { CircleS },

    props: {
        title: String,
        data: Object,
        spent: Number,
        ads: Number,
        views: Number,
        clicks: Number,
        circleColor: String,
    },
};
</script>

<style scoped>
.circle-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; /* Помещаем контейнер на задний план */
}
</style>
