<template>
    <div class="grid grid-cols-1 md:grid-cols-5 gap-4 min-h-[calc(100vh-80px)]">
        <div class="sticky top-16 bg-white rounded p-4 overflow-y-auto"><Menu /></div>

        <div class="col-span-4">
            <template v-if="admin && admin.accounts && !adminMenu"><Accounts :accounts="admin.accounts" /></template>

            <template v-if="adminAccount && adminAccount.id > 0">
                <template v-if="adminMenu == 'Clients' && adminAccount.clients"><Clients :clients="adminAccount.clients" /></template>
            </template>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAdminStore } from "@/store/admin";
import { useProfileStore } from "@/store/profile";

import Menu from "./components/Menu.vue";
import Accounts from "./components/Accounts.vue";
import Clients from "./components/Clients.vue";

export default {
    name: "AdminPage",

    components: { Menu, Accounts, Clients },

    data() {
        return {
            roleNames: { An: "Админ", Or: "Владелец", Cl: "Клиент", Mn: "Менеджер" },
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "setPopup", "closePopup"]),
        ...mapState(useAdminStore, ["admin", "adminMenu", "adminAccount", "getAdmin"]),
        ...mapState(useProfileStore, ["profile", "getProfile", "getPermission", "uploadAvatar"]),
    },

    mounted() {
        this.getAdmin();
        // this.getPermission("/admin");
    },
};
</script>
