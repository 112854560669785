<template>
    <div
        v-if="channel?.spent > 0"
        class="w-full grid grid-cols-11 justify-center items-center gap-2 text-right text-sm bg-white/50 hover:bg-white rounded-xl h-10 group"
    >
        <div class="flex flex-col justify-center items-center">
            <div v-if="title == 'vk'" class="text-cyan-600 group-hover:text-blue-500"><IVk :size="6" /></div>
            <div v-if="title == 'vkads'" class="text-cyan-600 group-hover:text-cyan-500"><IVkAds :size="6" /></div>
            <div v-if="title == 'market'" class="text-cyan-600 group-hover:text-pink-500"><IMarket :size="6" /></div>
            <div v-if="title == 'book'" class="text-cyan-600 group-hover:text-blue-500"><IFb :size="6" /></div>
            <div v-if="title == 'yandex'" class="text-cyan-600 group-hover:text-red-500"><IYa :size="6" /></div>
            <div v-if="title == 'google'" class="text-cyan-600 group-hover:text-yellow-500"><IGg :size="6" /></div>
            <div v-if="title == 'tgads'" class="text-cyan-600 group-hover:text-cyan-500"><ITgads :size="6" /></div>
            <div v-if="title == 'youtube'" class="text-cyan-600 group-hover:text-red-500"><IYoutube :size="6" /></div>
            <div v-if="title == 'base'" class="text-cyan-600 group-hover:text-green-500"><IBase :size="6" /></div>
            <div v-if="title == 'none'" class="text-cyan-600 group-hover:text-cyan-500"><IconClose :size="6" /></div>
        </div>

        <div class="latobold !text-pink-500">{{ parseInt(channel.spent).toLocaleString() }}</div>
        <!-- <div class="latolight">{{ parseInt(channel.ads || 0).toLocaleString() }}</div> -->
        <div class="latolight">{{ parseInt(channel.views || 0).toLocaleString() }}</div>
        <div class="latolight">{{ parseInt(channel.clicks || 0).toLocaleString() }}</div>
        <div class="latobold text-cyan-500">{{ parseInt(channel.regs || 0).toLocaleString() }}</div>
        <div class="latolight">{{ parseInt(channel.web1 || 0).toLocaleString() }}</div>
        <div class="latolight">{{ parseInt(channel.orderc || 0).toLocaleString() }}</div>
        <div class="latolight">{{ parseInt(channel.orders || 0).toLocaleString() }}</div>
        <div class="latolight">{{ parseInt(channel.paymentc || 0).toLocaleString() }}</div>
        <div class="latobold !text-teal-500">{{ parseInt(channel.payments || 0).toLocaleString() }}</div>
        <div class="pr-2">{{ parseFloat((channel.payments * 100) / channel.spent).toFixed(2) }}%</div>

        <!-- <div class="flex flex-col ">
            <div class="">{{ parseInt((channel.spent * 1000) / channel.views).toLocaleString() }}</div>
             <span class="text-xs text-slate-400">CPM</span>
       </div> 

        <div class="flex flex-col ">
            <div class="">{{ parseInt(channel.spent / channel.clicks).toLocaleString() }}</div>
        <span class="text-xs text-slate-400">CPC</span> 


        <div class="flex flex-col ">
            <div v-if="channel.views > 0 && channel.clicks / channel.views > 0.01" class="">
                {{ parseFloat(channel.clicks / channel.views).toFixed(2) }}
    
            <div v-else class="">0.00</div>
        <span class="text-xs text-slate-400">CTR</span> 


        <div class="flex flex-col ">
            <div v-if="channel.regs > 0" class="">{{ parseInt(channel.spent / channel.regs).toLocaleString() }}</div>
            <div v-else class="">0</div>
        <span class="text-xs text-slate-400">CPL</span> 

        -->
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        channel: Object,
    },
};
</script>
