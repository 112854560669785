<template>
    <div :style="circleStyle" class="circle" v-if="parentWidth && parentHeight" ref="circle"></div>
</template>

<script>
export default {
    name: "CircleS",

    props: {
        color: {
            type: String,
            default: "rgba(0, 0, 255, 0.5)", // значение по умолчанию, если цвет не передан
        },
    },

    data() {
        return {
            parentWidth: 0,
            parentHeight: 0,
        };
    },

    mounted() {
        this.getParentSize();
        this.startAnimation();
    },

    methods: {
        getParentSize() {
            const parent = this.$el.parentNode;
            if (parent) {
                this.parentWidth = parent.clientWidth; // Ширина родительского контейнера
                this.parentHeight = parent.clientHeight; // Высота родительского контейнера
            }
        },
        startAnimation() {
            setInterval(() => {
                const circle = this.$refs.circle;
                if (circle) {
                    const translateY = Math.random() > 0.5 ? "50px" : "-50px";
                    const translateX = Math.random() > 0.5 ? "50px" : "-50px";
                    circle.style.transition = "transform 5s ease-in-out";
                    circle.style.transform = `translate(${translateX}, ${translateY})`;
                }
            }, 5000);
        },
    },

    computed: {
        circleStyle() {
            const size = Math.floor(Math.random() * 251) + 50; // случайный размер от 50px до 300px

            // Проверяем, что родительский контейнер существует и имеет размеры
            if (this.parentWidth && this.parentHeight) {
                const top = Math.random() * (this.parentHeight - size); // случайное положение по вертикали
                const left = Math.random() * (this.parentWidth - size); // случайное положение по горизонтали

                return {
                    width: `${size}px`,
                    height: `${size}px`,
                    top: `${top}px`, // Используем пиксели вместо процентов
                    left: `${left}px`, // Используем пиксели вместо процентов
                    position: "absolute",
                    borderRadius: "50%",
                    backgroundColor: this.color, // использование переданного цвета
                };
            } else {
                return {}; // Возвращаем пустой стиль, если родительский контейнер еще не готов
            }
        },
    },
};
</script>

<style scoped>
.circle {
    position: absolute;
    border-radius: 50%;
}
</style>
