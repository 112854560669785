<template>
    <svg :class="'w-' + size + ' h-' + size" :width="size * 4" :height="size * 4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M0,2.4h7.2c2.7,0,4.8,2.1,4.8,4.8V24c0-2-1.6-3.6-3.6-3.6H0V2.4z" fill="currentColor" />
        <path d="M24,2.4h-7.2c-2.7,0-4.8,2.1-4.8,4.8V24c0-2,1.6-3.6,3.6-3.6H24V2.4z" fill="currentColor" />
    </svg>
</template>

<script>
export default {
    name: "IFb",
    props: {
        size: {
            type: Number,
            default: 5,
        },
    },
};
</script>
