<template>
    <div class="w-full relative overflow-hidden p-0 m-0" :class="h">
        <div :id="'chart' + name" class="w-full relative" :class="h"></div>
    </div>
</template>

<script>
import { createChart } from "lightweight-charts";
import moment from "moment";

export default {
    name: "LineWebGraf",

    props: {
        graf: {
            type: Array,
        },
        h: {
            type: String,
            default: "h-28",
        },
        name: {
            type: String,
            default: "first",
        },
    },

    data() {
        return {
            chart: null,
            moment: moment,
        };
    },

    computed: {
        grafData() {
            return this.graf.map((value, index) => ({
                time: moment().startOf("day").add(index, "minutes").unix(),
                value: value,
            }));
        },
    },

    watch: {
        graf() {
            this.showGraf();
        },
    },

    methods: {
        showGraf() {
            document.getElementById("chart" + this.name).innerHTML = "";

            this.chart = createChart(document.getElementById("chart" + this.name), {
                rightPriceScale: {
                    visible: false,
                    borderVisible: false,
                },
                timeScale: {
                    borderVisible: false,
                    visible: true,
                    timeVisible: true, // отображать время
                    tickMarkFormatter: (time) => {
                        const date = new Date(time * 1000);
                        return `${date.getHours()}:${date.getMinutes().toString().padStart(2, "0")}`;
                    },
                },
                layout: {
                    background: { color: "transparent" },
                },
                grid: {
                    horzLines: {
                        color: "#eee",
                    },
                    vertLines: {
                        color: "transparent",
                    },
                },
                autoSize: true,
            });

            this.sumLine = this.chart.addAreaSeries({
                topColor: "rgba(41, 181, 204, 0.56)",
                bottomColor: "rgba(41, 181, 204, 0.04)",
                lineColor: "rgba(41, 181, 204, 1)",
                lineWidth: 2,
                lineType: 2,
                priceLineVisible: false,
                priceFormat: { minMove: 1, precision: 0 },
            });
            this.sumLine.setData(this.grafData);

            this.chart.applyOptions({ autoSize: true, handleScroll: false, handleScale: false, crosshair: { mode: "Magnet" } });

            this.chart.timeScale().fitContent();
        },
    },

    mounted() {
        this.showGraf();
        window.addEventListener("resize", this.showGraf());
    },
};
</script>
