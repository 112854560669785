<template>
    <div class="grid grid-cols-main min-h-screen gap-4">
        <div class="w-32 sticky top-16 max-h-[calc(100vh-100px)] z-30">
            <FilterComponent :view="['date']" @update="update" />
        </div>

        <div class="w-full px-4" v-if="clients">
            <div class="grid grid-cols-12 items-center gap-4 p-2 mt-4 text-xs text-slate-400">
                <div class="col-span-2">КАНАЛ ID</div>
                <div class="col-span-7">КАБИНЕТ</div>
                <div class="text-right">БЮДЖЕТ</div>
                <div class="text-right">ПОКАЗЫ</div>
                <div class="text-right">КЛИКИ</div>
            </div>

            <div v-for="item in clients" :key="item.id" class="w-full rounded-xl relative my-1 text-slate-500 text-sm group hover:my-2 duration-500">
                <div class="bg-white/50 h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-4 hover:bg-white">
                    <div class="flex justify-start items-center gap-4 col-span-2 text-cyan-600">
                        <div v-if="item.channel_id == 1" class="group-hover:text-blue-500 group-hover:scale-110 duration-300"><IVk :size="5" /></div>
                        <div v-if="item.channel_id == 2" class="group-hover:text-cyan-500 group-hover:scale-110 duration-300">
                            <IVkAds :size="5" />
                        </div>
                        <div v-if="item.channel_id == 3" class="group-hover:text-pink-500 group-hover:scale-110 duration-300">
                            <IMarket :size="5" />
                        </div>
                        <div v-if="item.channel_id == 4" class="group-hover:text-indigo-500 group-hover:scale-110 duration-300">
                            <IFb :size="5" />
                        </div>
                        <div v-if="item.channel_id == 5" class="group-hover:text-red-500 group-hover:scale-110 duration-300"><IYa :size="5" /></div>
                        <div v-if="item.channel_id == 7" class="group-hover:text-yellow-400 group-hover:scale-110 duration-300">
                            <IGg :size="5" />
                        </div>
                        <div v-if="item.channel_id == 9" class="group-hover:text-teal-500 group-hover:scale-110 duration-300">
                            <ITgads :size="5" />
                        </div>
                        <div v-if="item.channel_id == 10" class="group-hover:text-red-500 group-hover:scale-110 duration-300">
                            <IYoutube :size="5" />
                        </div>
                        <div v-if="item.channel_id == 100" class="group-hover:text-green-500 group-hover:scale-110 duration-300">
                            <IBase :size="5" />
                        </div>
                        <div v-if="item.channel_id == 'none'" class="group-hover:text-slate-500 group-hover:scale-110 duration-300">
                            <IconClose :size="5" />
                        </div>

                        <!-- <div class="text-cyan-600">
                            <a v-if="item.channel_id == 1" target="_blank" :href="'https://vk.com/ads?act=office&union_id=' + item.id">
                                <IVk :size="5" />
                            </a>

                            <a v-if="item.channel_id == 2" target="_blank" href="https://ads.vk.com/hq/dashboard"><IVkAds :size="5" /> </a>

                            <a v-if="item.channel_id == 3" target="_blank" :href="'https://vk.com/ads?act=office&union_id=' + item.id">
                                <IVkCamp :size="5" />
                            </a>

                            <a v-if="item.channel_id == 4"><IFb :size="5" /> </a>
                            <a v-if="item.channel_id == 5"><IYa :size="5" /> </a>

                            <a v-if="item.channel_id == 9"><ITgads :size="5" /></a>
                            <a v-if="item.channel_id == 10"><IYoutube :size="5" /></a>
                            <a v-if="item.channel_id == 100"><IBase :size="5" /></a>
                        </div> -->

                        <div class="text-slate-500">{{ item.client_id }}</div>
                    </div>

                    <div class="col-span-7">{{ item.name }}</div>

                    <div class="text-right tabular-nums text-cyan-600">{{ item.spent?.toLocaleString() }}&nbsp;<sup>₽</sup></div>

                    <div class="text-right tabular-nums">{{ item.views?.toLocaleString() }}</div>

                    <div class="text-right tabular-nums">{{ item.clicks?.toLocaleString() }}</div>

                    <!-- <div class="absolute top-2 right-2 text-sky-800 cursor-pointer" @click="parseClientCampaigns(item.id)">
                        <IconDown :size="5" />
                    </div> -->
                </div>
            </div>

            <div class="h-10 rounded-xl grid grid-cols-12 justify-center items-center gap-4 px-4 font-bold text-sm text-slate-500">
                <div class="flex justify-start items-center gap-2 col-span-2">ИТОГО</div>

                <div class="col-span-7">Количество: {{ total.count }}</div>

                <div class="text-right tabular-nums text-cyan-600">{{ total.spent?.toLocaleString() }}&nbsp;<sup>₽</sup></div>

                <div class="text-right tabular-nums">{{ total.views?.toLocaleString() }}</div>

                <div class="text-right tabular-nums">{{ total.clicks?.toLocaleString() }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useClientStore } from "@/store/client";
import moment from "moment";

import FilterComponent from "../components/Filter.vue";

export default {
    name: "ClientsPage",

    components: { FilterComponent },

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "month", "profile", "setPeriod", "setMonth", "setSettings"]),
        ...mapState(useClientStore, ["clients", "getClients", "parseClientCampaigns"]),

        total() {
            if (!this.clients) return {};

            let data = { count: 0, views: 0, clicks: 0, spent: 0, cpm: 0, cpc: 0 };

            this.clients.forEach((client) => {
                data.count++;
                data.views = parseInt(data.views) + parseInt(0 + client.views);
                data.clicks = parseInt(data.clicks) + parseInt(0 + client.clicks);
                data.spent = parseInt(data.spent) + parseInt(0 + client.spent);
            });

            data.cpm = Math.round((data.spent / data.views) * 1000);
            data.cpc = Math.round(data.spent / data.clicks);

            return data;
        },
    },

    methods: {
        update() {
            this.getClients();
        },
    },

    mounted() {
        this.getClients();
    },
};
</script>
