<template>
    <div class="flex flex-col bg-white rounded p-8 mb-4">
        <span>НАСТРОЙКА ВОРОНКИ</span>
        <!-- <span class="title">СОЗДАЙ ПРОЕКТЫ</span>
        <span class="title">ДОБАВЬ КАБИНЕТЫ и ТОКЕНЫ</span>
        <span class="title">ДОБАВЬ ТАРГЕТОЛОГОВ (имя, фото, utm)</span>
        <span class="title">СКАЧАЙ СТАТИСТИКУ</span>

        <span class="title">ДОБАВЬ GETCOURSE и ТОКЕН</span>
        <span class="title">СКАЧАЙ СТАТИСТИКУ</span>
        <span class="title">УКАЖИ ТИПЫ EVENT</span>
        <span class="title"></span> -->
    </div>

    <div class="bg-white rounded p-8">
        <span class="title">СОБЫТИЯ</span>

        <div class="grid grid-cols-10 items-center bg-white gap-4 p-1 mt-4 text-xs text-slate-500 border-b border-slate-200">
            <div>ID</div>
            <div class="col-span-2">ПРОЕКТ</div>
            <div class="col-span-6">НАЗВАНИЕ</div>
            <div class="text-right">СТАТУС</div>
        </div>

        <div v-for="item in events" :key="item.id" class="grid grid-cols-10 items-center bg-white gap-4 p-1 text-sm border-b border-slate-200">
            <div class="text-slate-500">{{ item.id }}</div>

            <div class="col-span-2">{{ item.project }}</div>

            <div class="col-span-6">{{ item.title }}</div>

            <div class="text-right text-slate-500">{{ item.status }}</div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useAccountStore } from "@/store/account";
import { useFunnelStore } from "@/store/funnel";

import moment from "moment";

export default {
    name: "FunnelPage",

    data() {
        return {
            moment: moment,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "profile", "setPeriod", "setMonth", "setSettings"]),
        ...mapState(useAccountStore, ["funnel", "events"]),
        ...mapState(useFunnelStore, ["getFunnel"]),
    },

    mounted() {
        this.getFunnel();
    },
};
</script>
