<template>
    <div class="fixed z-50 flex justify-between items-center w-full h-12 px-4 hover:bg-white dark:hover:bg-slate-800 transition group duration-500">
        <a @click="$router.push(topMenu[0].url)" class="flex justify-between items-center text-xl font-bold text-cyan-600 cursor-pointer">
            <LogoAnalytics />

            <span v-if="s.project_name" class="uppercase">{{ s.project_name }}</span>
            <span v-else>АНАЛИТИКА</span>
        </a>

        <div class="flex justify-between items-center text-center text-sm h-12 gap-10 px-10">
            <div
                v-for="item in topMenu"
                :key="item.name"
                @click="$router.push(item.url)"
                class="menu_item flex items-center h-12 px-4 text-md text-slate-300 group-hover:text-slate-500 hover:!text-cyan-600 cursor-pointer duration-500"
            >
                {{ item.name }}

                <!-- <div v-if="['Mn'].includes(profile.role)" class="submenu absolute top-12 left-0 bg-white/75 bg-blur w-full mt-px z-40 backdrop-blur shadow-2xl">
                    <div class="flex justify-center mx-auto p-10 gap-20 text-left text-xl text-slate-500">
        
                        <div v-if="['An', 'Or'].includes(profile.role)">
                            <div
                                @click="$router.push('/admin/roadmap')"
                                class="flex justify-between items-center gap-4 bg-slate-100 py-2 px-4 rounded relative animate mt-10"
                                style="transition-delay: 0.2s"
                                :class="{
                                    '!text-pink-700': progress < 30,
                                    '!text-yellow-700': progress >= 30 && progress < 70,
                                    '!text-teal-700': progress >= 70,
                                }"
                            >
                                <div
                                    class="absolute top-0 left-0 h-full bg-gray-200 rounded"
                                    :style="{ width: progress + '%' }"
                                    :class="{
                                        'bg-pink-400': progress < 30,
                                        'bg-yellow-400': progress >= 30 && progress < 70,
                                        'bg-teal-400': progress >= 70,
                                    }"
                                ></div>

                                <div class="z-30 text-sm">ПРОГРЕСС ПРОЕКТА</div>
                                <div class="z-30 latobold justify-self-end">{{ progress }}%</div>
                            </div>
                        </div>

                        <div v-if="['An', 'Or'].includes(profile.role)">
                            <div class="text-xs animate" style="transition-delay: 0.05s">ДАННЫЕ</div>
                            <div @click="$router.push('/manager/projects')" class="font-bold py-1 animate" style="transition-delay: 0.1s">Проекты</div>
                            <div @click="$router.push('/manager/cabinets')" class="font-bold py-1 animate" style="transition-delay: 0.15s">Кабинеты</div>
                            <div @click="$router.push('/manager/clients')" class="font-bold py-1 animate" style="transition-delay: 0.2s">Клиенты</div>
                            <div @click="$router.push('/manager/targetologs')" class="font-bold py-1 animate" style="transition-delay: 0.25s">Таргетологи</div>
                            <div @click="$router.push('/admin/users')" class="font-bold py-1 animate" style="transition-delay: 0.3s">Пользователи</div>
                        </div>

                        <div v-if="['An', 'Or'].includes(profile.role)">
                            <div class="text-xs animate" style="transition-delay: 0.3s">СТАТИСТИКА</div>
                            <div @click="$router.push('/owner/stat')" class="font-bold py-1 animate" style="transition-delay: 0.35s">Статистика</div>
                        </div>

                   

                        <div v-if="['Mn'].includes(profile.role)">
                            <div class="text-xs animate" style="transition-delay: 0.4s">ОТЧЕТЫ</div>
                            <div class="font-bold py-1 animate" style="transition-delay: 0.45s">Группы</div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="flex items-center h-12 gap-4 cursor-pointer" @click="$router.push('/profile')">
            <div class="flex flex-col text-sm text-slate-500">
                <span class="text-xs">{{ roles[profile?.role] }}</span>
                <span class="leading-4"> {{ profile?.name }}</span>
            </div>

            <div class="bg-white w-8 h-8 my-2 rounded-full overflow-hidden">
                <img
                    v-if="profile?.picture"
                    class="w-full h-full object-cover"
                    :src="'https://statapi.timekraken.ru/users/mini/' + profile?.picture"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "pinia";
import { useMainStore } from "@/store/main";
import { useProfileStore } from "@/store/profile";

import LogoAnalytics from "@/components/logo.vue";

export default {
    name: "TopMenu",

    components: { LogoAnalytics },

    data() {
        return {
            progress: 66,
        };
    },

    computed: {
        ...mapState(useMainStore, ["s", "roles"]),
        ...mapState(useProfileStore, ["profile", "menu"]),

        topMenu() {
            if (!this.menu || !Array.isArray(this.menu)) return [];

            let menu = this.menu;
            let data = [];

            // Добавляем текущий элемент в подменю родительского элемента
            menu.forEach((top) => {
                if (top.parent === 0) {
                    top.submenu = [];

                    menu.forEach((sub) => {
                        if (sub.parent == top.id) top.submenu.push(sub);
                    });

                    data.push(top);
                }
            });

            return data;
        },
    },
};
</script>

<style>
.submenu {
    display: block;
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease-in-out;
}

.menu_item:hover .submenu {
    max-height: 20rem;
}

.animate {
    opacity: 0;
    transform: translateY(-8px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.menu_item:hover .submenu .animate {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.1s; /* Задержка для анимации каждого элемента */
}
</style>

<!--template v-for="item in topMenu" :key="item.name">
    <div class="menu_item items-center text-md text-slate-500 cursor-pointer">
        {{ item.name }}
        <div class="submenu absolute top-12 left-0 bg-pink-100 w-full z-40 rounded-md">
            <div class="container mx-auto p-10 grid grid-cols-3 gap-4">
                <div v-for="sub in item.submenu" :key="sub.name" @click="$router.push(sub.url)" class="text-slate-600 p-2">
                    {{ sub.name }}
                </div>
            </div>
        </div>
    </div>
</template -->
