import { defineStore } from "pinia";
import API from "../services/api";
import router from "@/services/router";
import { useMainStore as mainStore } from "./main";
import { useProfileStore as ProfileStore } from "./profile";
import { processProject } from "../store_utils/project";
import { processChannels } from "../store_utils/channels";

export const useClientStore = defineStore("client", {
    state: () => ({
        projects: [],
        project: {},
        settings: [],

        dashboard: [],

        cabinets: [], // список кабинетов - для настройки и контроля

        monthly: [], // статистика по месяцам

        days: [], // статистика по дням
        groups: [], // группы вебинаров (воронки)
        webinars: [], // статистика по вебинарам

        users: [], // список покупателей
        user: {}, // один покупатель со всеми deals & payments

        orders: [],

        payments: [],

        // фильтр
        utms: [], // список Utm в проекте
        events: [], // список Utm в проекте
        products: [], // список Utm в проекте
    }),

    actions: {
        // Дашборд
        async getProjects() {
            mainStore().setProjectId();

            // mainStore().setRequest(true);
            const res = await API.GET("client/stat/projects-list");
            if (res.data.alert) mainStore().setAlert(res.data.alert);

            // Обработка данных перед сохранением в store
            this.projects = processProject(res.data.projects);

            // mainStore().setRequest(false);
        },

        pickProject(project) {
            mainStore().setProjectId(project);
            // this.project = project;
            // localStorage.setItem("project", JSON.stringify(project));

            let filter = { source: [], medium: [], campaign: [], content: [], term: [], webinars: [], events: [], products: [], targetologs: [] };

            if (this.project.utms?.source) filter.source = this.project.utms?.source;
            if (this.project.utms?.medium) filter.medium = this.project.utms?.medium;
            if (this.project.utms?.campaign) filter.campaign = this.project.utms?.campaign;
            if (this.project.utms?.content) filter.content = this.project.utms?.content;
            if (this.project.utms?.term) filter.term = this.project.utms?.term;

            if (this.project.webinars) filter.webinars = this.project.webinars;
            if (this.project.events) filter.events = this.project.events;
            if (this.project.products) filter.products = this.project.products;
            if (this.project.targetologs) filter.targetologs = this.project.targetologs;

            mainStore().setFilter(filter);
            router.push("/dashboard");
        },

        settingsProject(project) {
            if (!project || !mainStore().s.project_id) router.push("/projects");

            mainStore().setProjectId(project);
            // this.project = project;
            // localStorage.setItem("project", JSON.stringify(project));

            router.push("/settings");
        },

        // // получаем данные для ФИЛЬТРА
        // async getUtms() {
        //     const res = await API.GET("data/filter", { project_id: mainStore().s.project });

        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     // console.log("getUtms", res.data.utms);
        //     this.utms = res.data.utms;
        //     this.webs = res.data.webinars;
        //     this.events = res.data.events;
        //     this.products = res.data.products;
        // },

        // Дашборд
        async getDashboard() {
            // mainStore().setRequest(true);
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/dashboard", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);

            if (!res.data.dashboard) router.push("/projects");
            this.filter = res.data.filter;

            // Обработка данных перед сохранением в store
            this.dashboard = res.data.dashboard;
            if (res.data?.dashboard?.channels) this.dashboard.channels = processChannels(res.data?.dashboard?.channels);

            // mainStore().setRequest(false);
        },

        // Клиентские кабинеты
        async getClients() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/clients", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.clients = res.data.clients;
        },

        // Выбрать покупателя
        pickUser(user) {
            this.user = user;
            mainStore().setPopup("userInfo");
        },

        // Выбрать покупателя по ID
        pickUserId(user_id) {
            this.user = {};
            this.user.id = user_id;
            mainStore().setPopup("userInfo");
        },

        async getUsers() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/users", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.users = res.data.users;
        },

        async getOrders() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/orders", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.orders = res.data.orders;
        },

        async getPayments() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/payments", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.payments = res.data.payments;
        },

        async getMonthly() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/monthly", { project_id: mainStore().s.project_id });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.monthly = res.data.monthly;
        },

        // получаем данные по дням
        async getDays() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/days", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
                channel_id: mainStore().s.channel_id,
                utms: mainStore().s.utms,
            });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (!res.data.days) router.push("/projects");
            this.days = res.data.days;
        },

        // получаем данные по дням
        async getWebinars() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/stat/webinars", {
                srt: mainStore().s.start,
                end: mainStore().s.end,
                project_id: mainStore().s.project_id,
            });
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (!res.data.webinars) router.push("/projects");
            this.groups = res.data.groups;
            this.webinars = res.data.webinars;
        },

        // Покупатель
        async getUser() {
            // mainStore().setRequest(true);
            console.log("user_id", this.user.id);

            const res = await API.GET("client/stat/user", { user_id: this.user.id });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            this.user = res.data.user;

            // mainStore().setRequest(false);
        },

        // НАСТРОЙКИ

        // получить настройки проекта
        async getProjectSettings() {
            if (!mainStore().s.project_id) router.push("/projects");

            const res = await API.GET("client/setting/get", { project_id: mainStore().s.project_id });

            if (res.data.alert) mainStore().setAlert(res.data.alert);
            if (!res.data.settings) router.push("/projects");
            this.settings = res.data.settings;
        },

        // создать ручной расход
        async createHandStat(data) {
            console.log("createHandStat data", data);

            let formData = new FormData();
            formData.append("name", data.name);
            formData.append("date", data.date);
            formData.append("channel_id", data.channel_id);
            formData.append("spent", data.spent);
            // formData.append("agency_id", data.agency_id);
            formData.append("agency_id", 1);
            // formData.append("client_id", data.client_id);
            formData.append("client_id", 1010);
            formData.append("project_id", mainStore().s.project_id);
            formData.append("user_id", ProfileStore().profile.id);

            const res = await API.POST("client/setting/create-hand", formData);
            console.log("createHandStat res", res.data);
            if (res.data.alert) mainStore().setAlert(res.data.alert);
            // this.clients = res.data.clients;
        },

        // ...

        // ...

        // ...

        // ...

        // ...

        // // получаем данные по дням
        // async getWebinars() {
        //     // mainStore().setRequest(true);

        //     const res = await API.GET("data/webinars", { srt: mainStore().s.start, end: mainStore().s.end, project_id: mainStore().s.project });
        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     this.webinars = res.data.webinars;

        //     // mainStore().setRequest(false);
        // },

        // // получаем заказы
        // async getClients(data) {
        //     const res = await API.GET("analytics/clients", { srt: data.srt, end: data.end });

        //     if (res.data.alert) mainStore().setAlert(res.data.alert);
        //     mainStore().setPopup("clients");
        //     this.clients = res.data.clients;
        // },

        // Фильтр по UTM
        setUtmSource(utm) {
            const index = this.utmsFilter.source.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.source.push(utm);
            } else {
                this.utmsFilter.source.splice(index, 1);
            }
        },

        setUtmMedium(utm) {
            const index = this.utmsFilter.medium.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.medium.push(utm);
            } else {
                this.utmsFilter.medium.splice(index, 1);
            }
        },

        setUtmCampaign(utm) {
            const index = this.utmsFilter.campaign.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.campaign.push(utm);
            } else {
                this.utmsFilter.campaign.splice(index, 1);
            }
        },

        setUtmContent(utm) {
            const index = this.utmsFilter.content.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.content.push(utm);
            } else {
                this.utmsFilter.content.splice(index, 1);
            }
        },

        setUtmTerm(utm) {
            const index = this.utmsFilter.term.indexOf(utm);

            if (index === -1) {
                this.utmsFilter.term.push(utm);
            } else {
                this.utmsFilter.term.splice(index, 1);
            }
        },
    },
});
