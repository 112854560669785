<template>
    <div class="w-12 h-12 relative">
        <div
            v-for="circle in circles"
            :key="circle"
            :style="circle.style"
            class="absolute rounded-full opacity-80"
            :class="{
                'bg-pink-400 w-8 h-8': circle == 1,
                'left-4 bg-cyan-400 w-5 h-5': circle == 2,
                'top-6 left-5 bg-teal-400 w-3 h-3': circle == 3,
            }"
            ref="circles"
        ></div>
    </div>
</template>

<script>
export default {
    name: "AnimatedCircles",

    data() {
        return {
            circles: [1, 2, 3],
        };
    },

    methods: {
        startAnimation() {
            setInterval(() => {
                const circles = this.$refs.circles;

                circles.forEach((circle) => {
                    if (circle) {
                        const translateX = Math.random() > 0.5 ? "10px" : "-10px";
                        const translateY = Math.random() > 0.5 ? "10px" : "-10px";
                        circle.style.transition = "transform 5s ease-in-out";
                        circle.style.transform = `translate(${translateX}, ${translateY})`;
                    }
                });
            }, 5000);
        },
    },

    mounted() {
        this.startAnimation();
    },
};
</script>
